'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('./CommonMethods').default} CommonMethods
 */

import ClassLogger from 'ClassLogger';

export default class BookingHandler {
    /**
     * Returns the class name used by the ClassLogger.
     *
     * @returns {string}
     */
    getClassName () {
        return 'BookingHandler';
    }

    /**
     * Create a new instance.
     *
     * @param {CommonMethods} commonMethods
     */
    constructor (commonMethods) {
        /** @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging
        this.commonMethods = commonMethods;
        this.api_url = '/api/bookings/';

        this.commonMethods.isDomReady().then(() => {
            this.init();
        });
    }

    /**
     * Initialize
     *
     * @returns {this}
     */
    init () {
        const self = this;
        const bookingButtons = document.querySelectorAll('[data-booking-delete]');

        bookingButtons.forEach((button) => {
            button.addEventListener('click', () => {
                self.deleteBooking(button);
            });
        });
    }

    /**
     * Renders messages from api call
     * @param {string} messageBlockSelector
     * @param {string} message
     * @param {HTMLElement} activeDialog
     */
    renderMessage (messageBlockSelector, message, activeDialog) {
        const messageBlock = activeDialog.querySelector(messageBlockSelector);
        messageBlock.innerHTML = message;
        messageBlock.classList.remove('u-hide');
    }

    /**
     * Disable buttons after being clicked
     * @param {HTMLElement} activeDialog
     */
    disableButtons (activeDialog) {
        const buttons = activeDialog.querySelectorAll('.c-button');
        buttons.forEach(button => {
            button.classList.add('is-disabled');
        });
    }

    /**
     * Hides content elements within a selected dialog window
     * @param {HTMLElement} activeDialog
     */
    hideDialogElements (activeDialog) {
        const heading = activeDialog.querySelector('.o-headline');
        const buttons = activeDialog.querySelector('[data-button-group]');

        heading.classList.add('u-hide');
        buttons.classList.add('u-hide');
    }

    /**
     * Show loader
     * @param {HTMLElement} activeDialog
     */
    showLoader (activeDialog) {
        const loader = activeDialog.querySelector('.o-loader');
        loader.classList.remove('u-hide');
    }

    /**
     * Deletes a booking using the API
     * Expects the button with bookingId
     * @param {HTMLElement} booking
     */
    deleteBooking (booking) {
        const requestOptions = {
            method: 'DELETE',
        };

        const bookingId = booking.dataset.bookingDelete;
        if (!bookingId) {
            return;
        }
        this.logger.log('Deleting Booking with id', bookingId);

        const activeDialog = document.querySelector('.c-dialog[aria-hidden="false"]');
        if (activeDialog === null) {
            // The dialog component always adds this attribute to show the proper dialog, but just in case, something
            // breaks there, we just kill this script.
            this.logger.error('Dialog is missing an aria-hidden value and cannot be used.');
            return;
        }
        this.disableButtons(activeDialog);

        fetch(this.api_url + bookingId + '/', requestOptions)
            .then(response => response.json())
            .then(resp => {
                if (!resp.success) {
                    throw new Error(resp.data.message);
                }
                this.renderMessage('[data-booking-success]', resp.data.message, activeDialog);
            })
            .catch(error => {
                this.renderMessage('[data-booking-error]', error, activeDialog);
                this.logger.error(error);
            })
            .finally(() => {
                this.hideDialogElements(activeDialog);
                this.showLoader(activeDialog);
                setTimeout(function () {
                    location.reload();
                }, 1000);
            });
    }
}
